:root {
  --primary-bg: #b1a1ed;
  --color-2: #e8e8e8;
  --color-3: #acbcff;
  --color-4: #aee2ff;
  --color-5: #e6fffd;
  --color-6: #fffcf5;
  --color-7: #f6efe8;
  --text-primary: #494a4d;
}

/* General Styling */

body {
  background-color: var(--primary-bg);
}

.container {
  max-width: 1200px;
}

.test {
  background-color: red;
}

.hidden {
  display: none;
}

.primary-background {
  background-color: var(--color-6);
  margin-left: 10px;
  margin-right: 10px;
}

.secondary-background {
  background-color: var(--color-7);
  margin-left: 10px;
  margin-right: 10px;
}

.primary-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Fonts */

.primary-text {
  font-family: "Bodoni Moda", serif;
}

.secondary-text {
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 25px;
}

.bold {
  font-weight: bolder;
}

.center-text {
  text-align: center;
}

.flexbox-vert {
  display: flex;
  flex-direction: column;
}

.flexbox-horiz {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 65px;
}

/* Navbar */

.navbar-background {
  background-color: var(--primary-bg);
  position: sticky;
  top: 0px;
  z-index: 100;
}

.navbar {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}

.navbar>.links>a {
  margin-left: 20px;
  padding: 20px;
}

a {
  font-weight: bold;
  font-size: 20px;
  color: var(--color-6);
}

a:hover,
#videos-link a:hover,
#events-link a:hover {
  color: var(--color-4);
  text-decoration: none;
  transition: 200ms;
}

.navbar img {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.navbar img:hover {
  transform: scale(110%);
  transition: 200ms;
}

.navbar .dropdown {
  display: none;
}

/* Dropdown  */

.dropbtn {
  background-color: var(--primary-bg);
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -55px;
  top: 53px;
  background-color: var(--primary-bg);
  border-bottom-left-radius: 10%;
  min-width: 170px;
  box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: var(--color-3);
  text-decoration: none;
  transition: 200ms;
}

.show {
  display: block;
}

/* Footer */

footer {
  padding: 50px;
  text-align: center;
}

/* HOME */

.title-top {
  font-size: 58px;
  color: var(--text-primary);
  font-weight: bolder;
  white-space: nowrap;
}

.title-top::first-letter {
  text-transform: capitalize;
}

.title-bottom {
  font-size: 25px;
  color: var(--primary-bg);
  letter-spacing: 3px;
  font-weight: bolder;
  white-space: nowrap;
}

.hero-image {
  height: 400px;
  width: 400px;
  border-radius: 5%;
  object-fit: cover;
  object-position: 0px -15px;
}

.hero-image-alt {
  height: 75px;
}

#hero p {
  font-size: 17px;
  width: 500px;
  margin-top: 10px;
}

#hero .flexbox-horiz,
#hero-alt .flexbox-horiz {
  justify-content: center;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

#videos-link,
#events-link {
  padding-top: 50px;
  padding-bottom: 50px;
}

#videos-link a,
#events-link a {
  color: #b1a1ed;
  padding-right: 100px;
}

#video-home .primary-padding {
  padding-bottom: 10px;
}

/* Videos Page */

#videos .flexbox-horiz,
#video-home .flexbox-horiz {
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}

#videos .secondary-background h2,
#videos .secondary-background h3,
#videos .secondary-background p,
#video-home,
#events-link {
  text-align: right;
}

iframe {
  border: solid transparent 5px;
}

iframe:hover {
  border: solid var(--primary-bg) 5px;
  transition: 200ms;
}

.video-text {
  max-width: 450px;
}

/* Resume Page */

#resume img {
  border: double 20px;
  background-color: white;
  justify-content: center;
  margin-bottom: 100px;
}

/* Events Page */

.event-table {
  padding-left: 80px;
  padding-right: 80px;
}

.primary-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#upcoming-events-table,
#past-events-table {
  padding-top: 50px;
  padding-bottom: 25px;
}

.event-list-title {
  text-align: center;
  padding-bottom: 50px;
}

.no-events {
  margin-bottom: 0px;
  padding-bottom: 50px;
}

.btn {
  background-color: #b1a1ed;
}

.btn:hover {
  background-color: #acbcff;
}

/* Contact */

.contact-form {
  width: 400px;
  position: relative;
  margin: auto;
  gap: 2px;
}

.contact-form label {
  margin-top: 5px;
}

/* Gallery */
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.gallery-image {
  width: 400px;
  height: 400px;
  margin: auto;
  object-fit: cover;
  border-radius: 15px;
}

.gallery-description {
  font-size: 20px;
  width: 400px;
  height: 100px;
  text-align: center;
}

/* Device Adjustments */

@media (max-width: 1200px) {
  body {
    text-align: center;
  }

  .container {
    max-width: 98%;
  }

  .flexbox-horiz {
    justify-content: space-evenly;
    gap: 25px;
  }

  #videos .flexbox-horiz,
  #video-home .flexbox-horiz {
    flex-direction: column-reverse;
  }

  #videos .primary-background .flexbox-horiz,
  #hero .flexbox-horiz {
    flex-direction: column;
    padding: 0;
  }

  #videos .secondary-background h2,
  #videos .secondary-background h3,
  #videos .secondary-background p,
  #video-home,
  #events-link {
    text-align: center;
  }

  #videos-link a,
  #events-link a {
    padding-right: 0px;
  }

  .event-table {
    padding-left: 0;
    padding-right: 0;
  }

  tr,
  .btn {
    font-size: 12px;
  }

  tr h3,
  tr p {
    font-size: 15px;
    font-weight: bold;
  }

  #videos .flexbox-horiz,
  #video-home .flexbox-horiz {
    padding-left: 0;
    padding-right: 0;
  }

  .contact-form {
    text-align: left;
  }
}

@media (max-width: 1000px) {
  .navbar .dropdown {
    display: inline-block;
  }

  .links {
    display: none;
  }

  nav ul .dropdown-logo {
    display: block;
    width: 50px;
    border: solid 5px;
  }
}

@media (max-width: 700px) {
  h2 {
    font-size: 29px;
  }

  .title-top {
    font-size: 29px;
  }

  .title-bottom {
    font-size: 13px;
    letter-spacing: 1.5px;
  }

  #hero p {
    font-size: 10px;
    width: 250px;
  }

  .hero-image {
    width: 200px;
    height: 200px;
  }

  .primary-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #resume img {
    margin-bottom: 25px;
  }

  .hero-image-alt {
    height: 50px;
  }

  .event-list-title {
    font-size: 25px;
  }

  iframe {
    width: 225px;
    height: 190px;
  }

  .video-text h3 {
    font-size: 20px;
  }

  .video-text p {
    font-size: 15px;
  }

  tr,
  .btn {
    font-size: 10px;
  }

  tr h3,
  tr p {
    font-size: 12px;
    font-weight: bold;
  }

  .contact-form {
    width: 300px;
  }

  .gallery-tab {
    margin-bottom: 10px;
  }

  .gallery-image {
    width: 250px;
    height: 250px;
  }

  .gallery-description {
    width: 250px;
  }

}